import React from 'react'
import { Helmet } from 'react-helmet'

import SEO from './seo'
import DecorationBottomSection from './decoration-bottom-section'
import Footer from './footer'
import Link from './link'

import './normalize.css'
import './main.less'
import './main/landing.sass'

const Layout = ({ className, children }) => (
  <>
    <Helmet>
      <body className={className} />
    </Helmet>
    <SEO />

    <div className="acquisition-banner">
      Undefined Labs is now a part of Datadog!{' '}
      <Link href="https://medium.com/scopedev/undefined-labs-is-now-a-part-of-datadog-b614f246b973">
        Learn more
      </Link>
    </div>
    {children}
    <DecorationBottomSection />
    <Footer />
  </>
)

export default Layout
